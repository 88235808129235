export const EDay = {
	NUMERIC: 'numeric',
} as const;

export const EMonth = {
	LONG: 'long',
} as const;

export const EYear = {
	NUMERIC: 'numeric',
} as const;
